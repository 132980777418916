import React from 'react'
import { Link } from 'gatsby'
import SideTitle from './sideTitle'
import { kebabCase } from 'lodash'

const Recommends = ({ tags }) => {
  return (
    <div className="blog-tags mb-12">
      <SideTitle data="Tags" />
      <ul className="mx-4 md:mx-0 flex flex-wrap mb-6">
        {tags.map(tag => (
          <li
            key={tag.fieldValue}
            className="bg-gray-600 text-sm text-gray-100 py-1 px-2 mr-2 mb-2 rounded"
          >
            <Link
              to={`/tags/${kebabCase(tag.fieldValue)}/`}
              className="tag hover:underline"
            >
              # {tag.fieldValue} ({tag.totalCount})
            </Link>
          </li>
        ))}
      </ul>
    </div>
  )
}

export default Recommends
