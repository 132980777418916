import { Link } from 'gatsby'
import React from 'react'
import SideTitle from './sideTitle'

const Profile = () => (
  <>
    <div className="w-full mb-12 bg-gray-200 p-8">
      {/* <SideTitle data="プロフィール" /> */}
      <div className="mx-6">
        <div className="flex flex-col items-center justify-center">
          <img className="w-24 rounded-full" src="/profile.svg"></img>
          <p className="mt-3 mb-2 text-lg text-center">
            醤油瓶(
            <a
              href="https://twitter.com/seuyu_bin"
              className="text-blue-700 hover:underline"
            >
              @seuyu_bin
            </a>
            )
          </p>
        </div>
        <div className="text-sm">
          元SIer、海外駐在3年、現在は都内でソフトウェアエンジニアをしています。IT、育児、日々の感じたことを投稿しています。
          <Link to="/profile" className="text-blue-700 hover:underline">
            詳細はこちら
          </Link>
        </div>
      </div>
    </div>
  </>
)

export default Profile
