import { Link } from 'gatsby'
import React from 'react'
import SideTitle from './sideTitle'

const SideLink = () => (
  <>
    <div className="w-full mb-6">
      <SideTitle data="Links" />
      <div className="mx-4">
        <ul className="ml-6 md:ml-2 list-disc leading-9">
          <li>
            <a
              href="https://blogmura.com/ranking/in?p_cid=11080183"
              target="_blank"
            >
              <img
                src="https://b.blogmura.com/banner-blogmura-landscape.svg"
                width="120"
                height="33"
                border="0"
                alt="ブログランキング・にほんブログ村へ"
                className="rounded"
              />
            </a>
          </li>
          <li>
            <a 
              className="hover:underline"
              href="https://blog.with2.net/link/?id=2053451"
              target="_blank">
              人気ブログランキングへ
            </a>
          </li>
        </ul>
      </div>
    </div>
  </>
)

export default SideLink
